// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SPORT = '/sport';
const ROOTS_FISHING = '/fishing';
const ROOTS_CARD = '/card';
const ROOTS_SLOT = '/slot';
const ROOTS_CASINO = '/casino';

const ROOTS_USER = '/user/';
const ROOTS_LOAN = '/loan/';
const ROOTS_REPAYMENT = '/repayment/';
const ROOTS_STAFF = '/staff/';
const ROOTS_CUSTOMER = '/customer/';
const ROOTS_SETTING = '/setting/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  fillInfo: path(ROOTS_AUTH, '/fill-info'),
  registerOtp: path(ROOTS_AUTH, '/register-otp'),
  registerPhonenumber: path(ROOTS_AUTH, '/register'),
  registerPassword: path(ROOTS_AUTH, '/register-password'),
  forgotPasswordOtp: path(ROOTS_AUTH, '/fp-otp'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
};

export const PATH_SELECT_INT = '/select-favorites';

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_SPORT = {
  root: ROOTS_SPORT,
  sportPrediction: path(ROOTS_SPORT, '/prediction'),
};

export const PATH_FISHING = {
  root: ROOTS_FISHING,
};

export const PATH_CARD = {
  root: ROOTS_CARD,
  slotPrediction: path(ROOTS_CARD, '/prediction'),
};

export const PATH_SLOT = {
  root: ROOTS_SLOT,
  slotPrediction: path(ROOTS_SLOT, '/prediction'),
};

export const PATH_CASINO = {
  root: ROOTS_CASINO,
  casinoPrediction: path(ROOTS_CASINO, '/prediction'),
  casinoRoom: path(ROOTS_CASINO, '/room'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
