import numeral from 'numeral';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fNumberDecimal(number) {
  return numeral(number || 0).format('0,0.[00]');
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function checkAndFormatPhoneNumber(inputString) {
  const retValue = { formattedNumber: inputString || '', countryCode: '' };
  if (!inputString) return retValue;

  // Parse the input string to get phone number information
  const phoneNumber = parsePhoneNumberFromString(inputString);

  // If the input is not a valid phone number, return the original string
  if (!phoneNumber || !phoneNumber.isPossible()) {
    return retValue;
  }

  // Get the formatted phone number without the country code
  retValue.formattedNumber = inputString.slice(phoneNumber.countryCallingCode.length + 1);

  // Get the country code
  retValue.countryCode = phoneNumber.country;

  // console.log(retValue);

  return retValue;
}
