// ----------------------------------------------------------------------

export default function Box() {
  return {
    MuiBox: {
      styleOverrides: {
        root: {
          position: 'relative',
          zIndex: 1,
        },
      },
    },
  };
}
