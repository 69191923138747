import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Link } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestLayout from '../layouts/guest/GuestLayout';
import AuthGuard from '../guards/AuthGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <GuestLayout />
        </GuestGuard>
      ),
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <RegisterNumber /> },
        { path: 'register-password', element: <RegisterPassword /> },
        { path: 'fill-info', element: <Register /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'fp-otp', element: <ForgotPasswordOTP /> },
        { path: 'fill-info', element: <Register /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <GuestLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'downloads/bringmefly.apk',
          element: <DownloadRedirect />,
        },
        {
          path: 'app-download',
          element: <DownloadApp />,
        }
      ],
    },
    // Select Favorites
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'select-favorites', element: <RegisterSelect /> },
      ],
    },
    // Home
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <HomePage /> },
        { path: 'dashboard/:uuid/:status', element: <HomePage /> },
      ],
    },
    // Sport
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'sport', element: <Sport /> },
        { path: 'sport/prediction', element: <SportPredictionPage /> },
      ],
    },
    // Fishing
    {
      path: '/',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'fishing', element: <Fishing /> },
      ],
    },
    // Card
    {
      path: '/',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'card', element: <Card /> },
      ],
    },
    // Slot
    {
      path: '/',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'slot', element: <Slot /> },
        { path: 'slot/prediction', element: <SlotPredictionPage /> },
      ],
    },
    // Casino
    {
      path: '/',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'casino', element: <Casino /> },
        { path: 'casino/prediction', element: <CasinoPredictionPage /> },
        { path: 'casino/room', element: <CasinoRoom /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const RegisterNumber = Loadable(lazy(() => import('../pages/auth/RegisterNumber')));
const RegisterPassword = Loadable(lazy(() => import('../pages/auth/RegisterPassword')));
const RegisterSelect = Loadable(lazy(() => import('../pages/auth/RegisterSelect')));
const ForgotPasswordOTP = Loadable(lazy(() => import('../pages/auth/ForgotPasswordOTP')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));

// HOME
const HomePage = Loadable(lazy(() => import('../views/dashboard/Home')));
const DownloadApp = Loadable(lazy(() => import('../views/dashboard/DownloadApp')));
const DownloadRedirect = Loadable(lazy(() => import('../views/dashboard/DownloadRedirect')));

// CASINO
const Casino = Loadable(lazy(() => import('../views/casino/Casino')));
const CasinoPredictionPage = Loadable(lazy(() => import('../views/casino/CasinoPredictionPage')));
const CasinoRoom = Loadable(lazy(() => import('../views/casino/CasinoRoom')));

// SLOT
const Slot = Loadable(lazy(() => import('../views/slots/Slot')));
const SlotPredictionPage = Loadable(lazy(() => import('../views/slots/SlotPredictionPage')));

// FISHING
const Fishing = Loadable(lazy(() => import('../views/fishing/Fishing')));

// CARDS
const Card = Loadable(lazy(() => import('../views/cards/Card')));

// SPORT
const Sport = Loadable(lazy(() => import('../views/sport/Sport')));
const SportPredictionPage = Loadable(lazy(() => import('../views/sport/SportPredictionPage')));
