import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import conObj from '../utils/connection-assistant';
import { PATH_AUTH } from '../routes/paths';

const extractUser = (accessToken) => {
  const { uname, uid } = jwtDecode(accessToken);

  return { id: uid, displayName: uname };
};

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        const newAccessToken = urlParams.get('token');

        if (newAccessToken && isValidToken(newAccessToken) && newAccessToken !== accessToken) {
          setSession(newAccessToken);

          const user = extractUser(newAccessToken);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else if (accessToken && isValidToken(accessToken)) {
          const user = extractUser(accessToken);
          setSession(accessToken);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const initializeLogin = (accessToken) => {
    if (accessToken && isValidToken(accessToken)) {
      const user = extractUser(accessToken);

      setSession(accessToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }
  };

  const logout = async () => {
    setSession(null);
    localStorage.clear();
    window.location.href = PATH_AUTH.login;
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        logout,
        initializeLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
