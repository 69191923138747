import { useEffect, useRef } from 'react';
import { usePageVisibility } from './usePageVisibility';

export default function useInterval(callback, delay) {
  const isVisible = usePageVisibility();
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    // Remember the latest callback if it changes.
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (typeof delay !== 'number' || delay === null || delay < 0 || !isVisible) return false;

    const tick = () => savedCallback.current();
    intervalRef.current = setInterval(tick, delay);
    return () => clearInterval(intervalRef.current);
  }, [isVisible, delay]);

  // return intervalRef;
}

export function useExpirationChecker(callbacksAndDates, intervalMS = 1000) {
  useEffect(() => {
    let interval;

    const checkExpiration = () => {
      let allDatesExpired = true;

      callbacksAndDates.forEach(({ callback, date }) => {
        const now = new Date().getTime();
        if (new Date(date).getTime() > now) {
          allDatesExpired = false;
        } else {
          callback();
        }
      });

      if (allDatesExpired) {
        clearInterval(interval);
      }
    };

    // Initial check on mount
    checkExpiration();

    interval = setInterval(checkExpiration, intervalMS);

    return () => clearInterval(interval);
  }, [callbacksAndDates, intervalMS]);
}
