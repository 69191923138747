import React from 'react';
import PropTypes from 'prop-types';
import { Card, alpha, styled } from '@mui/material';

export const BORDER_COLORS = {
  default: 'linear-gradient(45deg, #ff2579 1%, #ff9e0e 100%)',
  blue: 'linear-gradient(0deg, rgba(72,150,201,1) 0%, rgba(24,39,55,1) 50%)',
  // add more here
};

const BorderedCard = styled(Card)(({ theme, borderSize = 7, borderRadius = 7, borderColor = 'default' }) => ({
  position: 'relative',
  transformStyle: 'preserve-3d',
  borderRadius: `${borderRadius}px`,
  overflow: 'visible',
  border: `solid ${borderSize}px transparent`,
  backgroundColor: alpha(theme.palette.grey[900], 0.5),
  '& sh': {
    position: 'absolute',
    inset: `calc(0px - ${borderSize}px - 0px)`,
    border: `calc(0px + ${borderSize}px + 0px) solid #0000`,
    borderRadius: `calc(0px + ${borderSize}px + 0px + ${borderRadius}px)`,
    transform: 'translateZ(1px)',
    WebkitMask: 'linear-gradient(#000 0 0) content-box,linear-gradient(#000 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    pointerEvents: 'none',
  },
  '& sh::before': {
    content: '""',
    position: 'absolute',
    inset: `-${borderSize}px`,
    borderRadius: `${borderRadius}px`,
    background: BORDER_COLORS?.[borderColor] || BORDER_COLORS.default,
    filter: 'blur(0px)',
    transform: 'translate(0px, 0px)',
  },
}));

const GradientBorderCard = ({ borderSize = 3, borderRadius = 3, borderColor = 'default', children, sx, ...other }) => (
  <BorderedCard borderSize={borderSize} borderRadius={borderRadius} borderColor={borderColor} sx={sx} {...other}>
    <sh />
    {children}
  </BorderedCard>
);

GradientBorderCard.propTypes = {
  borderSize: PropTypes.number,
  borderRadius: PropTypes.number,
  children: PropTypes.node,
  sx: PropTypes.object,
  borderColor: PropTypes.string,
};

export default GradientBorderCard;
