// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const subMenuIcon = (ic) => <Iconify icon={ic} />;

const navConfig = [
  {
    subheader: '',
    items: [
      // USER
      // ----------------------------------------------------------------------
      // {
      //   title: 'user',
      //   path: PATH_USER.root,
      //   icon: subMenuIcon("bi:person-fill"),
      //   children: [
      //     { title: 'Google Authenticator', path: PATH_USER.googleAuth, icon: subMenuIcon("ant-design:google-outlined") },
      //     { title: 'Duty Scheduler', path: PATH_USER.dutyScheduler, icon: subMenuIcon("uil:schedule") },
      //     { title: 'Manage Admin', path: PATH_USER.manageAdmin, icon: subMenuIcon("fa-solid:user-cog") },
      //     { title: 'Manage Group', path: PATH_USER.manageGroup, icon: subMenuIcon("fa-solid:user-cog") },
      //     { title: 'Admin Amendment', path: PATH_USER.adminAmendment, icon: subMenuIcon("fa-solid:user-edit") },
      //     { title: 'Activity Log', path: PATH_USER.activityLog, icon: subMenuIcon("fa-solid:user-clock") },
      //     { title: 'Inactive Log', path: PATH_USER.inactiveLog, icon: subMenuIcon("fa-solid:user-times"), info: <Label color="error">6</Label> },
      //   ]
      // },
    ],
  },
];

export default navConfig;
