import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Chip, Grid } from '@mui/material';
// routes
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD, PATH_AUTH, PATH_SELECT_INT } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { useDispatch } from '../../../redux/store';
import { openCoinPackageForm } from '../../../redux/slices/lookup';
import { PRODUCT_TYPES } from '../../../config';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   linkTo: PATH_DASHBOARD.user.profile,
  // },
  // {
  //   label: 'Settings',
  //   linkTo: PATH_DASHBOARD.user.account,
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { memberInterests, userInfo } = useSelector((x) => x.lookup);

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const onUpdateInterest = () => {
    handleClose();
    navigate(PATH_SELECT_INT);
  };

  const memberInterestValues = useMemo(() => {
    const interests = [];
    memberInterests.forEach((x) => {
      const found = PRODUCT_TYPES.find((y) => y.value === x);
      if (found) interests.push(found);
    });

    return interests;
  }, [memberInterests]);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          mr: '10px',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar
          sx={{
            width: { xl: '40px', lg: '40px', md: '40px', sm: '40px', xs: '20px' },
            height: { xl: '40px', lg: '40px', md: '40px', sm: '40px', xs: '20px' },
          }}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userInfo?.displayName || user?.displayName}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Stack spacing={1}>
            <Typography variant="caption" fontWeight={500}>
              Your Favorites:{' '}
              <Typography
                variant="caption"
                color={'primary'}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={onUpdateInterest}
              >
                Edit
              </Typography>
            </Typography>
            <Box>
              {memberInterestValues?.length ? (
                <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                  {memberInterestValues.map((x, idx) => (
                    <Grid key={idx} item>
                      <Chip color={'primary'} size="small" clickable icon={<Iconify icon={x.icon} />} label={x.title} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                'Nothing'
              )}
            </Box>
          </Stack>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
          <MenuItem onClick={() => dispatch(openCoinPackageForm(true))}>Redeem</MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
