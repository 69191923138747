import React, { useEffect } from 'react';
import { CardContent, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import CustomModal from '../../components/CustomModal';
import { useDispatch, useSelector } from '../../redux/store';
import { setPaymentStatusModal } from '../../redux/slices/lookup';
import ShadowedText from '../../components/ShadowedText';
import GradientButton from '../../components/GradientButton';
import { PATH_DASHBOARD } from '../../routes/paths';

const PaymentStatusModal = () => {
  const { paymentStatusModal } = useSelector((x) => x.lookup);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { open, status } = paymentStatusModal;

  const handleClose = () => {
    dispatch(setPaymentStatusModal({ open: false, status: 0 }));
    navigate(PATH_DASHBOARD.root);
  };

  return (
    <CustomModal open={open} onClose={handleClose} isBackdropClosable={false}>
      <CardContent sx={{ borderRadius: 0 }}>
        <Stack sx={{ alignItems: 'center', mt: 2 }} spacing={5}>
          <Stack spacing={1} sx={{ alignItems: 'center', textAlign: 'center' }}>
            <ShadowedText variant={'h2'} fontWeight={900}>
              Transaction {status === '1' ? 'Success' : 'Failed'}!
            </ShadowedText>
            {status === '0' && (
              <ShadowedText variant={'h4'} fontWeight={900}>
                Please contact customer support!
              </ShadowedText>
            )}
          </Stack>
          <GradientButton onClick={handleClose} sx={{ px: 7 }}>
            <ShadowedText variant={'h4'} fontWeight={900}>
              Ok
            </ShadowedText>
          </GradientButton>
        </Stack>
      </CardContent>
    </CustomModal>
  );
};

export default PaymentStatusModal;
