import { AppBar, Box, Button, Container, Toolbar, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { HEADER } from '../../../config';
import cssStyles from '../../../utils/cssStyles';
import Scrollbar from '../../../components/Scrollbar';
import GradientButton from '../../../components/GradientButton';
import ShadowedText from '../../../components/ShadowedText';
import { PATH_CARD, PATH_CASINO, PATH_DASHBOARD, PATH_FISHING, PATH_SLOT, PATH_SPORT } from '../../../routes/paths';
import { dispatch, useSelector } from '../../../redux/store';
import { isProductAlreadyRedeemed, setSelectedRedeemable } from '../../../redux/slices/lookup';
import { openConfirm } from '../../../redux/slices/confirm';
import { useProdRedeemCheck } from '../../../hooks/useProdRedeemCheck';

const SecondNavbarStyle = styled(AppBar)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: '#0A0B24', opacity: 0.5 }),
  display: 'flex',
  alignItems: 'center',
  height: HEADER.MOBILE_HEIGHT,
  position: 'static',
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT - 15,
    // marginBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
  },
}));

export const PROD_ROUTES = [
  {
    title: 'Home',
    prodCode: 'HOME',
    path: PATH_DASHBOARD.root,
  },
  {
    title: 'Casino',
    prodCode: 'LD',
    path: PATH_CASINO.root,
  },
  {
    title: 'Slots',
    prodCode: 'SLOT',
    path: PATH_SLOT.root,
  },
  {
    title: 'Fishing',
    prodCode: 'FISH',
    path: PATH_FISHING.root,
  },
  {
    title: 'Cards',
    prodCode: 'CARD',
    path: PATH_CARD.root,
  },
  {
    title: 'Sports',
    prodCode: 'SB',
    path: PATH_SPORT.root,
  },
];

const SecondNavbar = () => {
  const location = useLocation();

  const navigate = useNavigate();

  return (
    <SecondNavbarStyle>
      <Toolbar sx={{ width: '100%', height: '100%' }}>
        <Container maxWidth={'sm'}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {PROD_ROUTES.map((x, idx) => {
              if (location.pathname.indexOf(x.path) !== -1) {
                return (
                  <GradientButton key={idx} onClick={() => navigate(x.path)}>
                    <ShadowedText>{x.title}</ShadowedText>
                  </GradientButton>
                );
              }
              return (
                <Button key={idx} sx={{}} onClick={() => navigate(x.path)}>
                  <ShadowedText>{x.title}</ShadowedText>
                </Button>
              );
            })}
          </Box>
        </Container>
      </Toolbar>
    </SecondNavbarStyle>
  );
};

export default SecondNavbar;
