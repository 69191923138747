// ----------------------------------------------------------------------

export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: '#fff',
          },
          // '& .MuiInputBase-root': { borderRadius: 20 },
        },
      },
    },
  };
}
