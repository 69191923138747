import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, DialogActions, Typography, IconButton, alpha } from '@mui/material';
import Iconify from './Iconify';
import { BORDER_COLORS } from './GradientBorderCard';

// ----------------------------------------------------------------------

CustomModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  actions: PropTypes.node,
  onClose: PropTypes.func,
  hasClose: PropTypes.bool,
  children: PropTypes.node,
  isBackdropClosable: PropTypes.bool,
  borderSize: PropTypes.number,
  borderRadius: PropTypes.number,
  borderColor: PropTypes.string,
};

export default function CustomModal({
  children,
  title = '',
  actions,
  open,
  onClose,
  hasClose = true,
  isBackdropClosable = true,
  borderSize = 4,
  borderRadius = 7,
  borderColor = 'default',
  ...other
}) {
  const handleClose = (e, r) => {
    if (!isBackdropClosable && r === 'backdropClick') {
      return false;
    }

    if (!isBackdropClosable && r === 'escapeKeyDown') {
      return false;
    }

    onClose(e, r);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: 'relative',
          transformStyle: 'preserve-3d',
          borderRadius: `${borderRadius}px`,
          overflow: 'visible',
          border: `solid ${borderSize}px transparent`,
          backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.95),
          '& sh': {
            position: 'absolute',
            inset: `calc(0px - ${borderSize}px - 0px)`,
            border: `calc(0px + ${borderSize}px + 0px) solid #0000`,
            borderRadius: `calc(0px + ${borderSize}px + 0px + ${borderRadius}px)`,
            transform: 'translateZ(1px)',
            WebkitMask: 'linear-gradient(#000 0 0) content-box,linear-gradient(#000 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
            pointerEvents: 'none',
          },
          '& sh::before': {
            content: '""',
            position: 'absolute',
            inset: `-${borderSize}px`,
            borderRadius: `${borderRadius}px`,
            background: BORDER_COLORS?.[borderColor] || BORDER_COLORS.default,
            filter: 'blur(0px)',
            transform: 'translate(0px, 0px)',
          },
        },
      }}
      open={open}
      onClose={handleClose}
      hideBackdrop={true}
      scroll="body"
      {...other}
    >
      <sh />
      {hasClose && (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 1,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon={'ep:close'} />
        </IconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}
