import { useEffect, useRef, useState } from 'react';
import { differenceInDays } from 'date-fns';
import { usePageVisibility } from './usePageVisibility';
import { getRemainingMilliseconds } from '../utils/formatTime';

export default function useTimeout(callback, delay) {
  const isVisible = usePageVisibility();
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null || delay < 0 || !isVisible) {
      return false;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [isVisible, delay]);
}

export const useMultiTimeout = (callbackDelayPairs, allowNegativeDelays = false) => {
  const isVisible = usePageVisibility();
  const timeoutRefs = useRef([]);

  const [invisibleCountdown, setInvisibleCountdown] = useState(0);

  // const [called, setCalled] = useState([]);

  useEffect(() => {
    if (!isVisible) setInvisibleCountdown((prev) => prev + 1);
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible || !callbackDelayPairs?.length) {
      timeoutRefs.current = [];
      timeoutRefs.current.forEach((timeoutId) => clearTimeout(timeoutId));
    } else {
      const timeouts = [];
      callbackDelayPairs.forEach(({ callback, date }) => {
        // Don't schedule if no delay is specified.
        const currentTime = new Date();
        const daysLeft = differenceInDays(date, currentTime);

        if (daysLeft < 5) {
          const delay = getRemainingMilliseconds(date);
          if (allowNegativeDelays && invisibleCountdown > 0 && delay < 0) {
            callback();
          } else if (delay > 0) {
            const timeoutId = setTimeout(callback, delay);
            timeouts.push(timeoutId);
          }
        }
      });
      timeoutRefs.current = timeouts;
    }

    return () => {
      timeoutRefs.current.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [allowNegativeDelays, callbackDelayPairs, invisibleCountdown, isVisible]);

  // Optionally, you can return the timeoutRefs.current if needed.
  // For example, to manually clear the timeouts outside the hook.

  return timeoutRefs.current;
};

// Usage
// useMultiTimeout([{callback: () => alert("Timeout!"), delay: 5000}])
