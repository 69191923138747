import jwtDecode from 'jwt-decode';
//
import { differenceInDays, addDays, getTime, addMinutes } from 'date-fns';
import axios from './axios';
import { fUnixTime } from './formatTime';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = fUnixTime(Date.now());

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  const expiry = new Date(exp * 1000);
  // const expiry = addMinutes(currentTime, 1);

  // Get Days between expiration
  const daysLeft = differenceInDays(expiry, currentTime);

  console.log('EXPIRY DAYS LEFT: %s days', daysLeft);

  if (daysLeft <= 5) {
    const timeLeft = getTime(expiry) - getTime(currentTime);

    console.log('TOKEN TIME LEFT EXPIRY: %s ms', timeLeft);

    clearTimeout(expiredTimer);

    expiredTimer = setTimeout(() => {
      // Auto logout on expire
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;

      window.location.reload();
    }, timeLeft);
  }
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { exp } = jwtDecode(accessToken); // ~5 days by minimals server

    // This function below will handle when token is expired
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
