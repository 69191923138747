import { useState } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  maxInput: PropTypes.number,
};

export default function RHFTextField({ name, ...other }) {
  const { control } = useFormContext();

  const [isShow, setIsShow] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          type={isShow ? 'text' : 'password'}
          fullWidth
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsShow(!isShow)} tabIndex={-1}>
                  <Iconify icon={isShow ? 'mdi:eye' : 'ri:eye-close-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...other}
        />
      )}
    />
  );
}
