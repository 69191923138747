import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import TawkTo from 'tawkto-react';
// hooks
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR, assetBucketLink, tawkToPropId, tawkToWidgetId } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import { PATH_SELECT_INT } from '../../routes/paths';
import {
  fetchCoinWalletBalance,
  fetchMemberInterests,
  fetchRedeemed,
  fetchUserInfo,
  setPaymentStatusModal,
} from '../../redux/slices/lookup';
import PaymentStatusModal from './PaymentStatusModal';
import Footer from './Footer';
import SecondNavbar from './header/SecondNavbar';
import { useMultiTimeout } from '../../hooks/useTimeout';
import { openConfirm } from '../../redux/slices/confirm';
import conObj from '../../utils/connection-assistant';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  // paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  maxWidth: 1200,
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 16,
    // paddingRight: 16,
    // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ROUTES to exclude daily check in trigger
export const XLD_DAILY_ALERT_PATHS = [PATH_SELECT_INT];

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { redeemed, memberInterests } = useSelector((x) => x.lookup);

  const [loadingFavs, setLoadingFavs] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'sm');

  const [open, setOpen] = useState(false);

  const params = useParams();

  const location = useLocation();

  const queryString = window.location.search;

  // const reqDailyChecking = async () => {
  //   try {
  //     const response = await conObj.post('api/user/checkIn');
  //     if (response.data === true) {
  //       openConfirm({
  //         title: 'DAILY LOGIN BONUS!',
  //         subTitle:
  //           "Congratulations! You've just earned extra coins! Log in every day to increase your earnings even further!",
  //         onConfirm: () => fetchCoinWalletBalance(),
  //         onCancel: () => fetchCoinWalletBalance(),
  //         isInfo: true,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // Checking for payments
  useEffect(() => {
    const urlParams = new URLSearchParams(queryString);

    const status = urlParams?.get('status');
    const uuid = urlParams?.get('uuid') || params?.uuid || null;

    if (status !== null && typeof status !== 'undefined') {
      dispatch(setPaymentStatusModal({ status, open: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.status, params?.uuid, queryString]);

  // Interval Fetchers
  const fetchers = () => {
    // fetchCoinWalletBalance();
    fetchUserInfo();
    fetchRedeemed();
  };

  useEffect(() => {
    setLoadingFavs(true);
    fetchMemberInterests().finally(() => setLoadingFavs(false));
    const tawk = new TawkTo(tawkToPropId, tawkToWidgetId);

    tawk.onStatusChange((status) => {
      // console.log(status)
    });
    fetchers(); // Initialize interval fetchers
  }, []);

  // useEffect(() => {
  //   if (XLD_DAILY_ALERT_PATHS.join(',').indexOf(location.pathname) === -1 && memberInterests?.length) {
  //     reqDailyChecking(); // Check daily login bonus
  //   }
  // }, [location?.pathname, memberInterests]);

  // Call every 15 seconds
  // useInterval(() => {
  //   fetchers();
  // }, 60000);

  // Checking for first time registered users
  useEffect(() => {
    if (!memberInterests?.length && !loadingFavs) {
      navigate(PATH_SELECT_INT);
    }
  }, [memberInterests, loadingFavs, navigate]);

  // Warning timeouts before 1 min expiry
  // useMultiTimeout(
  //   redeemed.map((x) => ({
  //     callback: () => {
  //       openConfirm({
  //         title: `You have 1 minute left for ${
  //           PRODUCT_TYPES.find((y) => y.value === x.productCode)?.title || 'this product'
  //         } session!`,
  //         subTitle: 'Do you want to extend?',
  //         onConfirm: () => dispatch(setSelectedRedeemable(x.productCode)),
  //       });
  //     },
  //     delay: getRemainingMilliseconds(x.redemptionExpiry) - 60000,
  //   }))
  // );

  // Timeouts after expiry
  useMultiTimeout(
    redeemed.map((x) => ({
      callback: () => {
        window.location.reload();
      },
      date: x.redemptionExpiry,
    })),
    true
  );

  return (
    <Box
      sx={{
        // display: 'flex',
        // flexDirection: 'column',
        minHeight: { lg: 1 },
        backgroundImage: `url("${assetBucketLink}/home/BG.jpg")`,
        position: 'relative',
        zIndex: 1,
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingFavs}>
        <CircularProgress color="primary" />
      </Backdrop>

      {isDesktop && XLD_DAILY_ALERT_PATHS.join(',').indexOf(location.pathname) === -1 ? <SecondNavbar /> : ''}

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
      {/* <LiveChatButton /> */}
      <Footer />

      <PaymentStatusModal />
      {/* <RedeemModal />
      <SingleRedeemModal /> */}
    </Box>
  );
};

export default DashboardLayout;
