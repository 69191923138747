import moment from 'moment';
import {
  format,
  getTime,
  formatDistanceToNow,
  getUnixTime,
  parse,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isFuture,
  differenceInMilliseconds,
} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return date ? format(new Date(date), 'dd MMMM yyyy') : '';
}

export function fDateDash(date) {
  return date ? format(new Date(date), 'yyyy-MM-dd') : '';
}

export function fDateSlash(date) {
  return format(new Date(date), 'yyyy/MM/dd');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeHSuffix(date) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fDateTimeHMSuffix(date) {
  return format(new Date(date), 'MMM dd yyyy p');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fUnixTime(date) {
  return getUnixTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateNumber(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateNumberMonth(date) {
  return format(new Date(date), 'MM/dd');
}

export function fDateToShortMonth(date) {
  return format(new Date(date), 'MMM dd');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function calculateRemainingDays(futureDateString) {
  const futureDate = moment(futureDateString);
  const currentDate = moment();

  if (!futureDate.isValid()) {
    return 'Invalid date format';
  }

  const remainingDays = futureDate.diff(currentDate, 'days');

  if (remainingDays < 0) {
    return 0;
  }

  return remainingDays + 1;
}

export const getRemainingMilliseconds = (targetTime) => {
  const currentTime = new Date();
  const converted = new Date(targetTime);

  // Check if the target time is in the future
  if (isFuture(converted)) {
    // Calculate the difference in milliseconds
    const difference = differenceInMilliseconds(converted, currentTime);

    // Return the remaining milliseconds
    return difference > 0 ? difference : 0;
  }

  return -1; // Return 0 if the target time is in the past or present
};

export function convertTimeStringToDuration(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  let duration = '';

  if (hours > 0) {
    // duration += `${hours} hour${hours > 1 ? 's' : ''} `;
    duration += `${hours} hr${hours > 1 ? 's' : ''} `;
  }

  if (minutes > 0) {
    // duration += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    duration += `${minutes} min${minutes > 1 ? 's' : ''} `;
  }

  if (seconds > 0) {
    // duration += `${seconds} second${seconds > 1 ? 's' : ''}`;
    duration += `${seconds} s`;
  }

  return duration.trim();
}

export function fDateTimeToDuration(dateTime) {
  const later = new Date(dateTime);
  const now = new Date();
  const years = differenceInYears(later, now);
  const months = differenceInMonths(later, now) % 12;
  const days = differenceInDays(later, now) % 30;
  const hours = differenceInHours(later, now) % 24;
  const minutes = differenceInMinutes(later, now) % 60;
  const seconds = differenceInSeconds(later, now) % 60;

  const duration = [];
  if (years > 0) {
    duration.push(`${years} yr${years > 1 ? 's' : ''}`);
  }
  if (months > 0) {
    duration.push(`${months} mth${months > 1 ? 's' : ''}`);
  }
  if (days > 0) {
    duration.push(`${days} day${days > 1 ? 's' : ''}`);
  }
  if (hours > 0) {
    duration.push(`${hours} hr${hours > 1 ? 's' : ''}`);
  }
  if (minutes > 0) {
    duration.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
  }
  if (seconds > 0) {
    duration.push(`${seconds} sec${seconds > 1 ? 's' : ''}`);
  }

  console.log('DURATION', minutes);

  return duration.join(', ');
}

export function getHoroscope(dateString) {
  const base = new Date(dateString);
  const date = getUnixTime(base);

  const aquariusStart = getUnixTime(parse('01-20', 'MM-dd', base));
  const piscesStart = getUnixTime(parse('02-19', 'MM-dd', base));
  const ariesStart = getUnixTime(parse('03-21', 'MM-dd', base));
  const taurusStart = getUnixTime(parse('04-20', 'MM-dd', base));
  const geminiStart = getUnixTime(parse('05-21', 'MM-dd', base));
  const cancerStart = getUnixTime(parse('06-22', 'MM-dd', base));
  const leoStart = getUnixTime(parse('07-23', 'MM-dd', base));
  const virgoStart = getUnixTime(parse('08-23', 'MM-dd', base));
  const libraStart = getUnixTime(parse('09-23', 'MM-dd', base));
  const scorpiusStart = getUnixTime(parse('10-24', 'MM-dd', base));
  const sagittariusStart = getUnixTime(parse('11-22', 'MM-dd', base));
  const capricornStart = getUnixTime(parse('12-22', 'MM-dd', base));

  if (date >= aquariusStart && date < piscesStart) {
    return 'Aquarius';
  }
  if (date >= piscesStart && date < ariesStart) {
    return 'Pisces';
  }
  if (date >= ariesStart && date < taurusStart) {
    return 'Aries';
  }
  if (date >= taurusStart && date < geminiStart) {
    return 'Taurus';
  }
  if (date >= geminiStart && date < cancerStart) {
    return 'Gemini';
  }
  if (date >= cancerStart && date < leoStart) {
    return 'Cancer';
  }
  if (date >= leoStart && date < virgoStart) {
    return 'Leo';
  }
  if (date >= virgoStart && date < libraStart) {
    return 'Virgo';
  }
  if (date >= libraStart && date < scorpiusStart) {
    return 'Libra';
  }
  if (date >= scorpiusStart && date < sagittariusStart) {
    return 'Scorpius';
  }
  if (date >= sagittariusStart && date < capricornStart) {
    return 'Sagittarius';
  }

  return 'Capricorn';
}

export function getZodiacSign(dateString) {
  // const date = fDateDash(dateString);

  // Reformat the date to get only the year
  const year = format(new Date(dateString), 'yyyy');

  // Define the Chinese zodiac signs and their corresponding years
  const zodiacSigns = {
    Rat: [1912, 1924, 1936, 1948, 1960, 1972, 1984, 1996, 2008, 2020, 2032, 2044],
    Ox: [1913, 1925, 1937, 1949, 1961, 1973, 1985, 1997, 2009, 2021, 2033, 2045],
    Tiger: [1914, 1926, 1938, 1950, 1962, 1974, 1986, 1998, 2010, 2022, 2034, 2046],
    Rabbit: [1915, 1927, 1939, 1951, 1963, 1975, 1987, 1999, 2011, 2023, 2035, 2047],
    Dragon: [1916, 1928, 1940, 1952, 1964, 1976, 1988, 2000, 2012, 2024, 2036, 2048],
    Snake: [1917, 1929, 1941, 1953, 1965, 1977, 1989, 2001, 2013, 2025, 2037, 2049],
    Horse: [1918, 1930, 1942, 1954, 1966, 1978, 1990, 2002, 2014, 2026, 2038, 2050],
    Goat: [1919, 1931, 1943, 1955, 1967, 1979, 1991, 2003, 2015, 2027, 2039, 2051],
    Monkey: [1920, 1932, 1944, 1956, 1968, 1980, 1992, 2004, 2016, 2028, 2040, 2052],
    Rooster: [1921, 1933, 1945, 1957, 1969, 1981, 1993, 2005, 2017, 2029, 2041, 2053],
    Dog: [1922, 1934, 1946, 1958, 1970, 1982, 1994, 2006, 2018, 2030, 2042, 2054],
    Pig: [1923, 1935, 1947, 1959, 1971, 1983, 1995, 2007, 2019, 2031, 2043, 2055],
  };

  // Find the corresponding zodiac sign for the given year
  const zodiacSign = Object.entries(zodiacSigns).find(([sign, years]) => years.includes(parseInt(year, 10)));

  return zodiacSign ? zodiacSign[0] : 'Invalid date';
}
