import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CardActionArea, CardContent, Grid, Stack, Typography, alpha, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import GradientBorderCard from '../../../components/GradientBorderCard';
import Iconify from '../../../components/Iconify';
import { fNumber } from '../../../utils/formatNumber';
import GradientButton from '../../../components/GradientButton';
import ShadowedText from '../../../components/ShadowedText';
import conObj from '../../../utils/connection-assistant';
import { PATH_DASHBOARD } from '../../../routes/paths';
import LinearLoader from '../../../components/LinearLoader';
import { currencyShortCode } from '../../../config';

const CardOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[800], 0.7),
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
}));

const CoinPackageForm = ({ onSuccess = () => {} }) => {
  const [packages, setPackages] = useState([]);

  const [loadingPackages, setLoadingPackages] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState(null);

  const [toppingUp, setToppingUp] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoadingPackages(true);
    conObj
      .get('api/user/coinPack')
      .then((response) => {
        setPackages(response.data);
      })
      .finally(() => {
        setLoadingPackages(false);
      });
  }, []);

  const handleSelectPackage = (value) => {
    if (selectedPackage === value) {
      setSelectedPackage(null);
    } else {
      setSelectedPackage(value);
    }
  };

  const handleSubmit = async () => {
    try {
      setToppingUp(true);
      const preUrl = `${window.location.protocol}//${window.location.hostname}${
        process.env.REACT_APP_DEV_PORT ? `:${process.env.REACT_APP_DEV_PORT}` : ''
      }`;
      const returnUrl = `${preUrl}${PATH_DASHBOARD.root}`; // dev

      const payload = {
        coinPackageTransCode: selectedPackage.id,
        returnUrl,
      };

      const response = await conObj.post('api/user/buyCoin2', payload);

      window.open(response.data.paymentLinkUrl, '_self');
    } catch (error) {
      console.log(error);
    } finally {
      // setToppingUp(false);
    }
  };

  if (loadingPackages)
    return (
      <Stack m={1}>
        <LinearLoader />
      </Stack>
    );

  return (
    <CardContent sx={{ borderRadius: 0 }}>
      <Stack spacing={2}>
        <Typography variant="h4" fontWeight={500}>
          Subscribe to Unlock Predictions
        </Typography>
        <Box>
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            {packages.map((x, idx) => (
              <Grid item xs={12} sm={6} key={idx}>
                <GradientBorderCard borderRadius={12}>
                  <CardActionArea sx={{ p: 4, position: 'relative' }} onClick={() => handleSelectPackage(x)}>
                    {selectedPackage?.id === x.id ? (
                      <CardOverlay>
                        <Iconify
                          icon="material-symbols:check-circle"
                          sx={{ color: 'success.main', width: 50, height: 50 }}
                        />
                      </CardOverlay>
                    ) : (
                      ''
                    )}
                    {/* <Typography variant="body2" sx={{ position: 'absolute', top: 10, right: 10 }} fontWeight={500}>
                      {currencyShortCode} {x?.currencyValue || '-'}
                    </Typography> */}
                    <Stack spacing={1} sx={{ alignItems: 'center' }}>
                      <Iconify
                        icon={'fa6-solid:coins'}
                        sx={{ fontSize: 40, color: (theme) => theme.palette.primary.main }}
                      />
                      <Typography variant="h3" fontWeight={900}>
                        {currencyShortCode} {fNumber(x?.applicableCoin || 0)}
                      </Typography>
                      {/* <Stack
                        direction={'row'}
                        spacing={1}
                        sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                      >
                        <Typography variant="h5">Coins</Typography>
                      </Stack> */}
                      <Typography variant="h4" fontWeight={500}>
                        {x?.transName || '-'}
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </GradientBorderCard>
              </Grid>
            ))}
          </Grid>
        </Box>
        {!!selectedPackage && (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <GradientButton onClick={handleSubmit} loading={toppingUp}>
              <ShadowedText variant="h5">Confirm</ShadowedText>
            </GradientButton>
          </Box>
        )}
      </Stack>
    </CardContent>
  );
};

CoinPackageForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default CoinPackageForm;
