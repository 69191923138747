import { useMemo, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Stack, Link, Container, Typography, Grid, Divider } from '@mui/material';
// routes
// components
import Page from '../../components/Page';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import GradientBorderCard from '../../components/GradientBorderCard';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Login">
      <Container maxWidth={'lg'} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid spacing={2} container>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 4, xs: 4 } }}>
            <GradientBorderCard
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.5),
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            // borderRadius={12}
            >
              <Stack spacing={3} sx={{ px: { xs: 2, sm: 2, md: 3, lg: 5, xl: 5 }, pt: 1, pb: 3 }}>
                <LoginForm />
                <Divider
                  variant="middle"
                  sx={{
                    borderImageSlice: 1,
                    borderImageSource: 'linear-gradient(270deg, #ff2579 1%, #ff9e0e 100%)',
                  }}
                />
                <Typography variant="body2" sx={{ textAlign: 'center' }} fontSize={{ md: '0.75rem', lg: '0.875rem' }}>
                  If you encounter any issues while logging in, Please contact our{' '}
                  <Link component={RouterLink} color={'primary'} to={''}>
                    Customer Service
                  </Link>{' '}
                  for further assistance.
                </Typography>
                <Image onClick={() => window.open('http://enigmaplay.asia/', '_blank')} src={require('../../assets/powered_by_enigmaplay.png')} sx={{ display: 'block', width: { sm: '100px', xs: '150px' }, mx: 'auto !important' }} />
              </Stack>
            </GradientBorderCard>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <Image
                src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/visitor/casino_predict_banner.png"
                sx={{ width: '100%' }}
              />
              <img
                src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/visitor/register_join_us_banner_v1.png"
                style={{ width: '100%', mt: 1, objectFit: 'contain', marginTop: '5px' }}
                alt="Join us banner"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
