import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isOpenCall: false,
  isOpenHardware: false,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // OPEN CALL
    openCall(state) {
      console.log('enter call')
      state.isOpenCall = true;
    },

    // CLOSE CALL
    closeCall(state) {
      console.log('enter call close')
      state.isOpenCall = false;
    },

    // OPEN HARDWARE
    openHardware(state) {
      state.isOpenHardware = true;
    },

    // CLOSE HARDWARE
    closeHardware(state) {
      state.isOpenHardware = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openCall, closeCall, openHardware, closeHardware } = slice.actions;
