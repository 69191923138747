export function capitalizeFirstLetter(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return '';
  }

  if (str.indexOf('_') !== -1) {
    // check if string is in snake case
    str = str
      .split('_')
      .map((word) => capitalizeFirstLetter(word))
      .join('');
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isInPhoneFormat(phoneNumber) {
  // const re = /^[+]?[0-9 -]{8,}$/;
  // return re.test(phoneNumber);
  if (phoneNumber.length >= 8) return true;
  return false;
}

export function truncateNotification(notificationHtml, maxLength) {
  if (notificationHtml.length > maxLength) {
    const truncatedHtml = notificationHtml.replace(/^(.{0,100})(?:.*?)?$/s, '$1...');
    return truncatedHtml;
  }
  return notificationHtml;
}

export function removeHtmlAndTruncate(notificationHtml, maxLength) {
  // Remove HTML tags from the notification
  const strippedText = notificationHtml.replace(/<[^>]+>/g, '');

  if (strippedText.length > maxLength) {
    const truncatedText = `${strippedText.slice(0, maxLength)}...`;
    return truncatedText;
  }
  return strippedText;
}
