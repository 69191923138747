import { createSlice } from '@reduxjs/toolkit';
// utils
import { dispatch } from '../store';
import conObj from '../../utils/connection-assistant';
import { fUnixTime } from '../../utils/formatTime';

// ----------------------------------------------------------------------

const initialState = {
  userInfo: null,
  coinWalletBalance: 0,
  isOpenCoinPackageForm: false,
  isOpenRedeemablesForm: false,
  selectedRedeemable: null,
  memberInterests: [],
  paymentStatusModal: {
    open: false,
    status: 0, // 1 = success, 0 = failed
  },
  redeemed: [],
  isFetchingRedeemed: true,
  notifications: {
    unreadCount: 0,
    count: 0,
    notifs: [],
  },
};

const slice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setCoinWalletBalance(state, action) {
      state.coinWalletBalance = action.payload;
    },
    setPaymentStatusModal(state, action) {
      state.paymentStatusModal = action.payload;
    },
    setRedeemed(state, action) {
      state.redeemed = action.payload;
    },
    setIsFetchingRedeemed(state, action) {
      state.isFetchingRedeemed = action.payload;
    },
    openCoinPackageForm(state, action) {
      state.isOpenCoinPackageForm = action.payload;
    },
    openRedeemablesForm(state, action) {
      state.isOpenRedeemablesForm = action.payload;
    },
    setSelectedRedeemable(state, action) {
      state.selectedRedeemable = action.payload;
    },
    setMemberInterests(state, action) {
      state.memberInterests = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setUserInfo,
  setCoinWalletBalance,
  setPaymentStatusModal,
  setRedeemed,
  openCoinPackageForm,
  openRedeemablesForm,
  setSelectedRedeemable,
  setSessions,
  setMemberInterests,
  setIsFetchingRedeemed,
  setNotifications,
} = slice.actions;

// Fetchers
export function fetchCoinWalletBalance() {
  return conObj.get('api/user/walletCoin').then((response) => {
    dispatch(setCoinWalletBalance(response.data));
  });
}

export function fetchRedeemed() {
  dispatch(setIsFetchingRedeemed(true));
  return conObj
    .get('api/user/redeemed')
    .then((response) => {
      dispatch(setRedeemed(response.data));
    })
    .finally(() => dispatch(setIsFetchingRedeemed(false)));
}

export function fetchMemberInterests() {
  return conObj.get('api/user/interest').then((response) => {
    dispatch(setMemberInterests(response.data));
  });
}

export function fetchNotifications() {
  return conObj.get('api/user/notif').then((response) => {
    const notifs = response.data?.notifs;
    if (notifs?.length) {
      dispatch(
        setNotifications({
          unreadCount: notifs.filter((x) => x.userRead === null)?.length || 0,
          count: notifs?.length || 0,
          notifs,
        })
      );
    }
  });
}

// Utils
export function isProductAlreadyRedeemed(productCode, redeemed) {
  let retValue = false;

  if (redeemed && redeemed?.length) {
    retValue =
      redeemed.findIndex(
        (x) => x.productCode === productCode && fUnixTime(x.redemptionExpiry) > fUnixTime(new Date())
      ) !== -1;
  }

  return retValue;
}

export function getRedeemedExpiry(productCode, redeemed) {
  if (!redeemed || !redeemed?.length) return null;

  const found = redeemed.find(
    (x) => x.productCode === productCode && fUnixTime(x.redemptionExpiry) > fUnixTime(new Date())
  );

  if (found) {
    return found.redemptionExpiry;
  }

  return null;
}

export function readNotifications() {
  return conObj.post('api/user/notif').finally(() => fetchNotifications());
}

export function fetchUserInfo() {
  return conObj.get('api/user/myInfo').then((response) => {
    dispatch(setUserInfo(response.data?.selfInfo));
  });
}
