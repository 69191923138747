import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title data-react-helmet="true">{`${title} | Most Trusted Casino Prediction App`}</title>
      <meta data-react-helmet="true" name="description" content="Introducing BringMeFly, the ultimate online casino prediction app designed to elevate your gaming experience and empower you with insights that could tip the odds in your favor. Whether you're a seasoned player or a newcomer to the online casino scene, BringMeFly is your go-to companion for informed decision-making and maximizing your winning potential." />
      {meta}
      <meta property="og:image" content="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/BMF_SEO.png" />
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
