import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, styled } from '@mui/material';

const COLOR_VARIANTS = {
  default: {
    textShadow: '1px 1px 4px black',
    fontColor: 'white',
    innerShadow: 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 50%)',
  },
  blood: {
    textShadow: '1px 1px 4px black',
    fontColor: 'white',
    innerShadow: 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 50%)',
  },
};

const StyledTypography = styled(Typography)(({ skew = 13, colorVariant = 'default' }) => ({
  transform: `skewX(-${skew}deg)`,
  backgroundColor: COLOR_VARIANTS[colorVariant].fontColor,
  backgroundImage: COLOR_VARIANTS[colorVariant].innerShadow,
  backgroundSize: 'cover',
  backgroundRepeat: 'repeat',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  MozBackgroundClip: 'text',
  MozTextFillColor: 'transparent',
}));

const ShadowedText = ({ skew, colorVariant = 'default', BoxStyles, children, ...other }) => (
  <Box sx={{ position: 'relative', display: 'flex', ...BoxStyles }}>
    {/* For Text Outer Shadow */}
    <StyledTypography
      fontWeight={800}
      skew={skew}
      colorVariant={colorVariant}
      sx={{ textShadow: COLOR_VARIANTS?.[colorVariant].textShadow, position: 'absolute' }}
      {...other}
    >
      {children}
    </StyledTypography>
    {/* For Text Inner Shadow */}
    <StyledTypography skew={skew} fontWeight={800} colorVariant={colorVariant} {...other}>
      {children}
    </StyledTypography>
  </Box>
);

ShadowedText.propTypes = {
  skew: PropTypes.number,
  colorVariant: PropTypes.string,
  TextProps: PropTypes.string,
  BoxStyles: PropTypes.object,
  children: PropTypes.string,
};

export default ShadowedText;
