/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import { noCase } from 'change-case';
import { useEffect, useState, useRef, useMemo } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  CardContent,
  Stack,
  styled,
  Card,
  Grid,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { fetchNotifications, readNotifications } from '../../../redux/slices/lookup';
import { useSelector } from '../../../redux/store';
import useInterval from '../../../hooks/useInterval';
import uuidv4 from '../../../utils/uuidv4';
import CustomModal from '../../../components/CustomModal';
import { removeHtmlAndTruncate } from '../../../utils/formatText';
import Image from '../../../components/Image';
import { appBucketLink, assetBucketLink } from '../../../config';

// ----------------------------------------------------------------------

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 4,
    top: 1,
    [theme.breakpoints.only('xs')]: {
      height: '11px',
      fontSize: '9px',
      padding: 0,
      minWidth: '18px',
    },
  },
}));

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
});

export default function NotificationsPopover() {
  const { notifications } = useSelector((x) => x.lookup);

  const [userNotifications, setUserNotifications] = useState([]);

  const [selectedNotif, setSelectedNotif] = useState(null);

  const [totalUnRead, setTotalUnRead] = useState(0);

  const [loadingNotif, setLoadingNotif] = useState(true);

  const [open, setOpen] = useState(null);

  const notificationRef = useRef(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    if (totalUnRead > 0) readNotifications();
  };

  const getNotifications = () => {
    setLoadingNotif(true);
    fetchNotifications().finally(() => setLoadingNotif(false));
  };

  useEffect(getNotifications, []);

  useInterval(getNotifications, 30000);

  useEffect(() => {
    if (notifications.unreadCount > totalUnRead && !loadingNotif) {
      notificationRef.current.volume = 0.5;
      notificationRef.current.play();
    }

    setTotalUnRead(notifications?.unreadCount);
    setUserNotifications(notifications?.notifs || []);
  }, [notifications, totalUnRead, loadingNotif]);

  const { read, unread } = useMemo(
    () => ({
      read: userNotifications?.filter((x) => x.userRead !== null),
      unread: userNotifications?.filter((x) => x.userRead === null),
    }),
    [userNotifications]
  );

  return (
    <>
      <Tooltip title="Notifications" placement="top" followCursor>
        <IconButtonAnimate
          size="small"
          color={open ? 'primary' : 'default'}
          onClick={handleOpen}
          sx={{ width: { xl: 35, lg: 35, md: 35, sm: 35, xs: 30 }, height: { xl: 35, lg: 35, md: 35, sm: 35, xs: 30 } }}
        >
          <StyledBadge
            badgeContent={totalUnRead < 0 ? 0 : totalUnRead}
            color="error"
            sx={{ height: { xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '15px' }, width: '100%' }}
            max={9}
          >
            <img
              src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/message.png"
              alt="Notification"
              // style={{ height: '100%', width: '100%' }}
            />
          </StyledBadge>
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} new messages
            </Typography>
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark these as read">
              <LoadingButton loading={isLoading} disabled={isLoading} color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </LoadingButton>
            </Tooltip>
          )} */}
        </Box>

        {unread.length || read.length ? <Divider sx={{ borderStyle: 'dashed' }} /> : <></>}

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {unread.length ? (
            <>
              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    New
                  </ListSubheader>
                }
              >
                {unread.map((notification) => (
                  <NotificationItem
                    key={uuidv4()}
                    onRead={() => setSelectedNotif(notification)}
                    notification={notification}
                  />
                ))}
              </List>
              <Divider sx={{ borderStyle: 'dashed', mt: 1 }} />
            </>
          ) : (
            []
          )}

          {read.length ? (
            <List
              disablePadding
              // subheader={
              //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
              //     Read
              //   </ListSubheader>
              // }
            >
              {read.map((notification) => (
                <NotificationItem
                  key={uuidv4()}
                  onRead={() => setSelectedNotif(notification)}
                  notification={notification}
                />
              ))}
            </List>
          ) : (
            []
          )}
        </Scrollbar>
      </MenuPopover>
      <CustomModal
        fullWidth
        maxWidth="md"
        title={'View Notification'}
        open={!!selectedNotif}
        onClose={() => setSelectedNotif(null)}
        isBackdropClosable={false}
      >
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h4" fontWeight={600}>
              {selectedNotif?.title || '-'}
            </Typography>
            {/* IMAGES HERE */}
            {selectedNotif?.promMedias?.length ? (
              <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                {selectedNotif?.promMedias?.map((x, idx) => (
                  <Grid item key={idx}>
                    <Card sx={{ borderRadius: 0.5, background: 'none' }}>
                      <Image src={x.objectPointer} />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              ''
            )}
            <Box>
              <div dangerouslySetInnerHTML={sanitize(selectedNotif?.message)} style={{ textIndent: '5%' }} />
            </Box>
          </Stack>
        </CardContent>
      </CustomModal>

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        style={{ display: 'none' }}
        ref={notificationRef}
        src={`${window.location.origin}/audio/notification.wav`}
      />
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, onRead = () => {} }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      onClick={onRead}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification?.userRead === null && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar || <Iconify icon="mdi:bell" />}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification?.creation)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {removeHtmlAndTruncate(notification.message)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
