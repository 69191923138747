// import React from 'react';
import axios from 'axios';
// import { Redirect, useHistory } from 'react-router-dom';
// import app from '../App';

/**
 * @description
 * axios instance with default
 * baseUrl & headers etc. for global use
 */
const conObj = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // headers, without this, it will throw unsupported media type error
    'content-type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    Accept: 'application/json',
    // Authorization: "Bearer " + localStorage.getItem("jwt")
  },
});

conObj.interceptors.request.use((request) => {
  // Need to check if jwt already expired?
  /**
   * Moved this piece of code here so that localStorage.getItem("jwt")
   * is always getting the latest value.
   * If follow old settings, still need to refresh the whole site to
   * reflect the latest localStorage values
   *  */
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) request.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  // request.headers.Authorization =
  //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbHBoYSI6MzcsImJldGEiOiJmZTljNzdiNS1lMDM3LTQwYTEtOTc1OS05MTNkMWU4NTRkMjMiLCJnYW1tYSI6IlRFU1QiLCJuYmYiOjE2NzY5NjM4NjgsImV4cCI6MTY3OTU1NTM1MywiaWF0IjoxNjc2OTYzODY4fQ.DwDnDcK-UVsbuNkuVUEhPxfkoDWcC8AQ_CQ6LrD2DwU';
  // saveLogs(request);
  return request;
});

conObj.interceptors.response.use(
  (response) => {
    // RefreshJwt();
    // saveLogs(response);
    return response;
  },
  (error) => {
    console.log('error:', error);
    // if (error.response.status === 401) {
    //   const lastPath = window.location.hash;
    //   sessionStorage.setItem('lastPath', lastPath.substring(1, lastPath.length));
    //   localStorage.clear();
    //   return window.location.replace('/');
    // }

    throw error;
  }
);

// function saveLogs(req_data) {
// const data = {
// secret: "secret",
// req_data: req_data,
// jwt: localStorage.getItem("jwt")
// };
// axios
// .post(process.env.REACT_APP_LOGGER_API, data, axiosConfig)
// .then(res => {
// //do nothing
// })
// .catch(err => {
// console.log("Something went wrong while calling save logs: " + err);
// });
// }

export default conObj;
