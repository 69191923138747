import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, styled } from '@mui/material';

export const GRADIENT_BUTTON_STYLE = ({
  theme,
  borderRadius,
  borderSize = 4,
  noBoxShadow = false,
  noBorder = false,
}) => ({
  color: theme.palette.common.white,
  background: `linear-gradient(0deg, rgba(225,53,111,1) 0%, rgba(250,170,31,1) 100%)`,
  boxShadow: noBoxShadow ? 'none' : '0px 0px 15px 5px rgba(252,176,69,0.75)',
  position: 'relative',
  backgroundClip: 'padding-box',
  border: `solid ${borderSize}px transparent`,
  // borderRadius: borderRadius || 20,
  borderRadius: borderRadius || theme.shape.borderRadius,
  ...(!noBorder
    ? {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          margin: `-${borderSize}px`,
          borderRadius: 'inherit',
          background: 'linear-gradient(0deg, rgba(255,78,0,1) 0%, rgba(255,241,159,1) 100%)',
        },
      }
    : {}),
});

const StyleGradientButton = styled(Button)(GRADIENT_BUTTON_STYLE);

const GradientButton = ({
  borderSize,
  borderRadius,
  loading,
  BoxStyles,
  children,
  noBoxShadow = false,
  noBorder = false,
  ...other
}) => (
  <Box sx={{ zIndex: 1, ...BoxStyles }}>
    <StyleGradientButton
      borderSize={borderSize}
      borderRadius={borderRadius}
      noBoxShadow={noBoxShadow}
      noBorder={noBorder}
      disabled={loading || false}
      variant={'default'}
      {...other}
    >
      {loading ? <CircularProgress size={16} sx={{ color: 'white' }} /> : children}
    </StyleGradientButton>
  </Box>
);

GradientButton.propTypes = {
  borderSize: PropTypes.string,
  borderRadius: PropTypes.string,
  isShadowedText: PropTypes.bool,
  children: PropTypes.any,
  BoxStyles: PropTypes.object,
  loading: PropTypes.bool,
  noBoxShadow: PropTypes.bool,
  noBorder: PropTypes.bool,
};

export default GradientButton;
