import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useMemo, useEffect } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// hooks
import { useLocation, useNavigate } from 'react-router';
import { RecaptchaVerifier } from 'firebase/auth';
import { parse } from 'date-fns';
import moment from 'moment';
import { auth } from '../../../firebase.config';

import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { FormProvider, RHFPassword, RHFPhonenumber, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import Image from '../../../components/Image';
import GradientButton from '../../../components/GradientButton';
import { PATH_AUTH } from '../../../routes/paths';
import ShadowedText from '../../../components/ShadowedText';
import conObj from '../../../utils/connection-assistant';
import { isInPhoneFormat } from '../../../utils/formatText';
import { handleSendOTP, initVerifier } from '../../../utils/firebaseUtil';
import { DEFAULT_COUNTRY_CODE } from '../../../config';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

LoginForm.propTypes = {};

export default function LoginForm() {
  const { initializeLogin } = useAuth();

  const location = useLocation();

  const navigate = useNavigate();

  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE.code);

  const [areaCode, setAreaCode] = useState(`+${DEFAULT_COUNTRY_CODE.phone}`);

  const [isWithPwd, setIsWithPwd] = useState(false);

  const LoginSchema = useMemo(
    () =>
      Yup.object().shape({
        isWithPwd: Yup.boolean(),
        phoneNumber: Yup.string()
          .nullable()
          .min(9 + areaCode?.length || 0, 'Enter at least 8 characters')
          // .max(13)
          .test(
            'phoneNumber',
            'Please enter a valid number',
            // (value) => isValidPhoneNumber(value, countryCode || DEFAULT_COUNTRY_CODE.code) || isInPhoneFormat(value)
            (value) => isValidPhoneNumber(value, countryCode || DEFAULT_COUNTRY_CODE.code) || isInPhoneFormat(value)
          )
          .label('Phone Number'),
        password: Yup.string().when('isWithPwd', {
          is: true,
          then: Yup.string().min(6).required().label('Password'),
        }),
      }),
    [countryCode, areaCode]
  );

  const defaultValues = {
    isWithPwd: false,
    phoneNumber: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    try {
      const toPass = {
        ...data,
        phoneNumber: data.phoneNumber.replace(/[^0-9+]/g, ''),
        country: countryCode,
      };

      if (isWithPwd) {
        const loginResponse = await conObj.post('api/user/anon/phpw', {
          phoneNumber: toPass.phoneNumber,
          password: toPass.password,
        });

        if (loginResponse?.data?.page === 'HOME') {
          initializeLogin(loginResponse?.data?.jwt);
        } else if (loginResponse?.data?.page === 'FILL_INFO') {
          navigate(PATH_AUTH.fillInfo, { state: { country: toPass.country, accessToken: loginResponse?.data?.jwt } });
        }
      } else {
        const response = await conObj.get(`api/user/anon/step1/${toPass.phoneNumber}`);
        if (response.data?.page === 'PASSWORD_LOGIN') {
          // API phpw
          setIsWithPwd(true);
        } else if (response.data?.page === 'VERIFY_OTP') {
          // User doesn't exist
          throw new Error("Phone Number doesn't exist!");
        } else if (response.data?.page === 'REGISTER_PASSWORD') {
          // Redirect to Password Registration
          navigate(PATH_AUTH.registerPassword, {
            state: { country: toPass.country, phoneNumber: toPass.phoneNumber, accessToken: response.data.jwt },
          });
        }
      }
    } catch (error) {
      // console.error(error);
      // reset();

      const errorMessage = error?.response?.data || error.message;
      setError('afterSubmit', { ...error, message: errorMessage });
    }
  };

  useEffect(() => {
    setValue('isWithPwd', isWithPwd);
  }, [isWithPwd, setValue]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        {/* <Image src={require('../../../assets/Ace333.png')} sx={{ width: '60%' }} /> */}

        <Box sx={{ textAlign: 'center', px: 4 }}>
          <Typography variant="body1" fontWeight={300}>
            Login to find out your lucky game now!
          </Typography>
        </Box>

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFPhonenumber
          name="phoneNumber"
          label="Phone Number"
          selectCode={setCountryCode}
          selectAreaCode={setAreaCode}
          sx={{ display: isWithPwd ? 'none' : 'block', color: 'white' }}
        />

        {isWithPwd && values?.phoneNumber ? (
          <Box sx={{ display: 'flex', opacity: 0.7 }}>
            <Iconify icon="ic:baseline-phone" />
            <Typography variant="body1">{values.phoneNumber}</Typography>
          </Box>
        ) : (
          ''
        )}

        <RHFSwitch name="isWithPwd" sx={{ display: 'none' }} />

        <Box sx={{ display: isWithPwd ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ opacity: 0.7, mb: 0.5 }} variant="caption">
            Please Input password to continue
          </Typography>
          <RHFPassword name="password" label="Password" sx={{ color: 'white' }} />

          <Typography
            variant="body2"
            sx={{ textAlign: 'end', mt: 0.5, textDecoration: 'underline', color: 'primary.main', cursor: 'pointer' }}
            fontSize={{ md: '0.75rem', lg: '0.875rem' }}
            onClick={() => navigate(PATH_AUTH.forgotPasswordOtp, { state: { phoneNumber: values?.phoneNumber } })}
          >
            {/* <Link component={RouterLink} color={'primary'} to={PATH_AUTH.forgotPasswordOtp}>
              Forgot Password
            </Link> */}
            Forgot Password
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ textAlign: 'center' }} fontSize={{ md: '0.75rem', lg: '0.875rem' }}>
          Don't have an account?{' '}
          <Link component={RouterLink} color={'primary'} to={PATH_AUTH.registerPhonenumber}>
            Register Here!
          </Link>
        </Typography>
        <GradientButton size="large" type="submit" loading={isSubmitting} sx={{ px: 4 }}>
          <ShadowedText variant={'h5'} fontWeight={900}>
            Submit
          </ShadowedText>
        </GradientButton>
      </Stack>
    </FormProvider>
  );
}
