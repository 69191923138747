import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';

const LinearLoader = () => (
  <Grid container spacing={2} justifyContent={'center'}>
    <Grid item xs={10}>
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Loading, Please Wait
      </Typography>
      <LinearProgress />
    </Grid>
  </Grid>
);

export default LinearLoader;
