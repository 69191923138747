import { PhoneAuthProvider, signInWithCredential, RecaptchaVerifier,  } from 'firebase/auth';
import { auth } from '../firebase.config';

export const initVerifier = () =>
  new RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'invisible',
      callback: () => {},
      'expired-callback': () => {},
    },
    auth
  );

export const handleSendOTP = (phoneNumber, appVerifier) => {
  const phoneProvider = new PhoneAuthProvider(auth);
  return phoneProvider.verifyPhoneNumber(phoneNumber, appVerifier);
};

export const handleVerifyOTP = (verificationId, code) => {
  const credential = PhoneAuthProvider.credential(verificationId, code);
  return signInWithCredential(auth, credential);
};