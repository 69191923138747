import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Container, IconButton, Toolbar, alpha } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import Image from '../../components/Image';
import { HEADER } from '../../config';
import GradientButton from '../../components/GradientButton';
import ShadowedText from '../../components/ShadowedText';
import { PATH_AUTH } from '../../routes/paths';
import useAuth from '../../hooks/useAuth';

const Navbar = ({ props }) => {
  const { logout, isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const location = useLocation();

  const buttonPath = useMemo(() => {
    const path = location.pathname;

    if (path.indexOf('register') !== -1) {
      return {
        title: 'Login',
        to: PATH_AUTH.login,
      };
    }

    return {
      title: 'Register',
      to: PATH_AUTH.registerPhonenumber,
    };
  }, [location]);

  const handleBtnClick = (pathTo) => {
    const path = location.pathname;
    if (path.indexOf('register') !== -1 && isAuthenticated) {
      logout();
    } else {
      navigate(pathTo);
    }
  };

  return (
    <AppBar
      sx={{
        position: 'unset',
        justifyContent: 'center',
        height: {
          xl: HEADER.MAIN_DESKTOP_HEIGHT,
          lg: HEADER.MAIN_DESKTOP_HEIGHT,
          md: HEADER.MAIN_DESKTOP_HEIGHT,
          sm: HEADER.MOBILE_HEIGHT,
          xs: HEADER.MOBILE_HEIGHT,
        },
        backgroundColor: (theme) => alpha(theme.palette.grey[800], 0.4),
        boxShadow: 'none',
      }}
    >
      <Toolbar component={Container} maxWidth="lg">
        <Box
          sx={{ display: { xl: 'block', lg: 'block', md: 'block', sm: 'none', xs: 'none' }, cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          <img
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/bringmefly-gif.gif"
            alt="App Logo"
            style={{ height: 60, width: 'auto', objectFit: 'contain' }}
          />
        </Box>

        <Box
          sx={{
            display: { xl: 'none', lg: 'none', md: 'none', sm: 'block', xs: 'block' },
            pl: { xl: 0, lg: 0, md: 0, sm: 2, xs: 0 },
            height: { xl: '60px', lg: '60px', md: '60px', sm: '60px', xs: '10vw' },
            cursor: 'pointer',
          }}
          onClick={() => navigate('/')}
        >
          <img
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/bringmefly-logo-small.png"
            alt="App Logo"
            style={{ height: '100%', width: 'auto' }}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <GradientButton sx={{ mr: 2, px: 3 }} onClick={() => handleBtnClick(buttonPath.to)} borderSize={2}>
          <ShadowedText fontSize={{ xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '12px' }} fontWeight={900}>
            {buttonPath.title}
          </ShadowedText>
        </GradientButton>
        <IconButton sx={{ pr: 0 }}>
          <Image
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/settings_logo.png"
            sx={{
              width: {
                xl: '100%',
                lg: '100%',
                md: '100%',
                sm: '100%',
                xs: '25px',
              },
              height: { xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '25px' },
            }}
          />
        </IconButton>
        <IconButton sx={{ pr: 0 }}>
          <Image
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/support_logo.png"
            sx={{
              width: {
                xl: '100%',
                lg: '100%',
                md: '100%',
                sm: '100%',
                xs: '25px',
              },
              height: { xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '25px' },
            }}
          />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {};

export default Navbar;
