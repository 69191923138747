import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';
import { assetBucketLink } from '../../config';
import Image from '../../components/Image';
import { IconButtonAnimate } from '../../components/animate';
import cssStyles from '../../utils/cssStyles';

const COMPANY_LOGOS = [
  'SAGaming',
  'AESexy',
  'WMCasino',
  'DreamGaming',
  'Digmann',
  'AKGaming',
  'Allbet',
  'WorldEntertainment',
  'PGSoft',
  'PragmaticPlay',
  'Joker',
  'Fachai',
  'Relax',
  'Kingmaker',
  'Ace333',
  'Yggdrasil',
  'AvatarUX',
];

const Footer = () => {
  const theme = useTheme();

  const dividedLogos = useMemo(() => {
    const divided = [];
    let childrens = [];
    COMPANY_LOGOS.forEach((x) => {
      if (childrens.length < 10) childrens.push(x);
      else {
        divided.push(childrens);
        childrens = [x];
      }
    });

    if (childrens.length) {
      divided.push(childrens);
    }

    return divided;
  }, []);
  return (
    <Stack sx={{ position: 'relative' }}>
      {/* <Box sx={{ py: 5, backgroundColor: '#1b1b1b' }} /> */}
      <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'black', alignItems: 'center', py: 2 }}>
        <Container maxWidth="lg" sx={{ my: 4 }}>
          <Grid container sx={{ px: { xl: 8, lg: 8, md: 8, sm: 8, xs: 1 } }}>
            <Grid item sm={4} xs={6}>
              <Stack spacing={1}>
                <Link color={'white'}>ABOUT US</Link>
                <Link color={'white'}>CONTACT US</Link>
                <Link color={'white'}>FAQ</Link>
              </Stack>
            </Grid>
            <Grid item sm={4} xs={0} sx={{ display: { sm: 'flex', xs: 'none' }, alignItems: 'center', justifyContent: 'center' }}>
              <Image onClick={() => window.open('http://enigmaplay.asia/', '_blank')} src={require('../../assets/powered_by_enigmaplay.png')} sx={{ width: '200px', cursor: 'pointer' }} />
            </Grid>
            <Grid item sm={4} xs={6}>
              <Stack spacing={1} sx={{ alignItems: 'flex-end' }}>
                <Typography>LIVE CHAT</Typography>
                <Box sx={{ width: '10%' }}>
                  <Image
                    src={`${assetBucketLink}/home/support_logo.png`}
                    sx={{ width: { xl: '50px', lg: '50px', md: '5vw', sm: '6vw', xs: '7vw' } }}
                  />
                </Box>
                <Typography variant="caption">24/7 Customer Service</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg" sx={{ mb: 4, display: { sm: 'none', xs: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
          <Image onClick={() => window.open('http://enigmaplay.asia/', '_blank')} src={require('../../assets/powered_by_enigmaplay.png')} sx={{ width: '100px', cursor: 'pointer' }} />
        </Container>
        <Container maxWidth="lg">
          <Stack spacing={2} sx={{ alignItems: 'center' }}>
            {dividedLogos.map((x, idx) => (
              <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* <Stack key={idx} direction={'row'} spacing={4} sx={{ alignItems: 'center', justifyContent: 'center' }}> */}
                {x.map((y, idx2) => (
                  <Grid item xl={1} lg={1} md={1} sm={1} xs={2} key={idx2} sx={{ p: 1 }}>
                    <img alt={y} src={`${assetBucketLink}/logos/${y}.png`} />
                  </Grid>
                ))}
                {/* </Stack> */}
              </Grid>
            ))}
          </Stack>
          <Divider variant="middle" sx={{ borderColor: 'white', mb: 2, mt: 6 }} />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="caption">COPYRIGHT ALL RIGHTS RESERVED</Typography>
          </Box>
        </Container>
      </Box>
    </Stack>
  );
};

export default Footer;
